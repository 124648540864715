.info-text {
  color: #212529;
}

/* .info-text-about {
  font-size: 1.0em;
  color: #212529;
} */

.card-data-text {
  font-size: 0.6em;
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.masonry-grid_col {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.graph-card {
  pading: 50px;
}

.url-card h4 {
  font-size: 1.2em;
  font-weight: 700;
  text-decoration: underline;
}